<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-center justify-content-between">
          <div class="col-11 no-padding">
            <button
              data-target="#addModal"
              data-toggle="modal"
              type="button"
              class="
                button
                page__body__header__button
                button--black
                text--capital
                ml-auto
              "
              v-if="userCanCreateGroupRoles"
            >
              <span class="d-inline-flex">Add New Group Role Name</span>
              <ion-icon name="add-outline" class="text--medium"></ion-icon>
            </button>
          </div>
        </div>
      </div>
      <!--
      <div class="row">
        <div class="col-8">
          <div class="row align-items-end">
            <div class="col-10">
              <div class="form__item">
                <label class="form__label">group role name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  disabled
                />
              </div>
            </div>
            <div class="form__item">
              <button
                data-modal="#deleteModal"
                type="button"
                class="button page__body__header__button button--grey text--capital"
              >
                <span class="d-inline-flex mr-2">delete</span>
                <img src="../../../assets/img/delete.svg" alt="" srcset="" />
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <div
        class="row"
        v-for="item in allGroupRoleNames"
        v-bind:key="item.groupRoleNameKey"
      >
        <div class="col-12">
          <div class="row align-items-end">
            <div class="col-7">
              <div class="form__item">
                <label class="form__label">group role name</label>
                <input
                  type="text"
                  :value="item.groupRoleName"
                  class="input form__input form__input--lg"
                  disabled
                />
              </div>
            </div>
            <div class="col-2 form__item">
              <button
                data-target="#editModal"
                data-toggle="modal"
                v-on:click="editGroupRoleName(item)"
                type="button"
                class="
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                v-if="userCanEditGroupRoles"
              >
                <span class="d-inline-flex mr-2">edit</span>
                <!-- <img src="../../../assets/img/delete.svg" alt="" srcset="" /> -->
              </button>
            </div>
            <div class="col-3 form__item">
              <button
                data-toggle="modal"
                data-target="#deleteModal"
                v-on:click="openDeleteConfirmation(item)"
                type="button"
                class="
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                v-if="userCanDeleteGroupRoles"
              >
                <span class="d-inline-flex mr-2">delete</span>
                <img src="../../../assets/img/delete.svg" alt="" srcset="" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            row per page:
            <span
              class="d-inline-block text--primary"
              style="padding-right: 15px"
            >
              <select @change="changeLength" v-model="size">
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="30">30</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>
            </span>
            <span class="d-inline-block text--primary">{{
              !paginations.hasPrevious
                ? paginations.currentPage
                : size * (paginations.currentPage - 1) + 1
            }}</span>
            -
            <span class="d-inline-block text--primary">{{
              paginations.hasNext
                ? size * paginations.currentPage
                : paginations.totalCount
            }}</span>
            of
            <span>{{ paginations ? paginations.totalCount : 0 }}</span>
          </div>
          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
            v-if="paginations"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              :disabled="!paginations.hasPrevious"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              v-for="page in paginations.totalPages"
              :key="page"
              :class="{
                'text--white button--primary ':
                  paginations.currentPage === page,
              }"
              class="button text--regular button--sm ml-2"
              @click="changePage(page)"
            >
              {{ page }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              :disabled="!paginations.hasNext"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal section starts here -->
    <div
      class="modal modal__right"
      id="addModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#addModal"
                class="modal__close modal__close--black"
              >
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="formCloseButton"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              add group role name
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveForm">
              <div class="form__item">
                <label class="form__label">group role name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter group role name"
                  v-model="form.groupRoleName"
                />
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                  >
                    save changes
                  </button>
                  <a
                    type="button"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    @click="clearForm"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="formCloseButton2"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Edit group role name
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEditForm">
              <div class="form__item">
                <label class="form__label">group role name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter group role name"
                  v-model="editForm.groupRoleName"
                />
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="!isSaving"
                  >
                    save changes
                  </button>
                  <a
                    type="button"
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    @click="clearForm"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Ends here -->
    <div
      class="modal modal__confirm"
      id="deleteModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                data-dismiss="modal"
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Group Role Names
            </span>
          </div>
          <div class="modal__content" v-if="groupRoleNameToDelete != null">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to delete this Group Role Name '{{
                  groupRoleNameToDelete.groupRoleName
                }}'
              </h5>
              <p class="form__label">
                when you delete this group role name. you cant reactivate it
                back on this table
              </p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="modal"
                  aria-label="Close"
                  id="deleteCloseButton"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                >
                  cancel
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="
                    button
                    form__button form__button--lg
                    w-25
                    modal__confirm__button modal__confirm__button--delete
                  "
                  v-if="isDeleting"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isDeleting"
                  v-on:click="deleteGroupRoleName()"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                >
                  delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";
import groupService from "@/core/services/general-setup-service/group.service";
export default Vue.extend({
  name: "group-roles",
  data: function () {
    return {
      content_header: {
        title: "Group roles",
        subTitle: "",
      },
      groupRoleNameToDelete: null,
      groupRoleName: null,
      isDeleting: false,
      isSaving: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      allGroupRoleNames: [],
      form: {
        groupRoleName: "",
      },
      editForm: {
        groupRoleNameKey: "",
        groupRoleName: "",
      },
      paginations: {},
      pages: 1,
      size: 20,
    };
  },
  methods: {
    async getGroupRoleNames() {
      try {
        this.isLoading = true;
        const res = await groupService.getGroupRoleNames({ pageSize: this.size, pageIndex: this.pages });
        this.allGroupRoleNames = res.data.data.data;
        this.paginations = res.data.data;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    changePage(type) {
      if (type === "next") {
        this.pages++;
      } else if (type === "previous") {
        this.pages--;
      } else {
        this.pages = type;
      }

      this.getGroupRoleNames();
    },

    changeLength(e) {
      const { value } = e.target;
      this.size = parseInt(value);
      this.pages = 1;
      this.getGroupRoleNames();
    },
    editGroupRoleName(item) {
      try {
        this.isLoading = true;
        this.groupRoleName = item;
        this.editForm = {
          groupRoleNameKey: item.groupRoleNameKey,
          groupRoleName: item.groupRoleName,
        };
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveForm: async function (e) {
      e.preventDefault();
      try {
        this.isSaving = true;
        await groupService.createGroupRoleName(this.form);
        this.getGroupRoleNames();
        this.clearForm();
        document.getElementById("formCloseButton").click();
        this.alertSuccess("New Group Role Name added.");
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.data || e.response.data.message;
        this.alertError(error);
      }
    },
    saveEditForm: async function (e) {
      e.preventDefault();
      try {
        this.isSaving = true;
        await groupService.updateGroupRoleName(
          this.editForm.groupRoleNameKey,
          this.editForm
        );
        this.getGroupRoleNames();
        this.clearForm();
        document.getElementById("formCloseButton2").click();
        this.alertSuccess("Group Role Name updated.");
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.data || e.response.data.message;
        this.alertError(error);
      }
    },
    openDeleteConfirmation(item) {
      this.groupRoleNameToDelete = item;
    },
    async deleteGroupRoleName() {
      if (this.groupRoleNameToDelete != null) {
        this.isDeleting = true;
        try {
          await groupService.deleteGroupRoleName(
            this.groupRoleNameToDelete.groupRoleNameKey
          );
          this.groupRoleNameToDelete = null;
          document.getElementById("deleteCloseButton").click();
          this.alertSuccess("Group Role Name deleted");
          this.isDeleting = false;
          this.getGroupRoleNames();
        } catch (e) {
          this.isDeleting = false;
          const error = e.response.data.message || "Something went wrong";
          this.alertError(error);
        }
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    clearForm: function () {
      this.groupRoleName = null;
      this.form = {
        groupRoleName: "",
      };
      this.editForm = {
        groupRoleNameKey: "",
        groupRoleName: "",
      };
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
  },
  async created() {
    this.getGroupRoleNames();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateGroupRoles() {
      const groupRoles = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = groupRoles;
      return permissionsList.CanCreateGroupRole;
    },
    userCanEditGroupRoles() {
      const groupRoles = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = groupRoles;
      return permissionsList.CanEditGroupRole;
    },
    userCanDeleteGroupRoles() {
      const groupRoles = this.user.scopes.find(item => item.scopeName === Role.GeneralSetup);
      const { permissionsList } = groupRoles;
      return permissionsList.CanDeleteGroupRole;
    },
  }
});
</script>
<style scoped>
.no-padding {
  padding: 0;
}
</style>
